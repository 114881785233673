.pannel {
  height: calc(100% - 75px);
  /* width: 100%; */
  padding: 40px 20px 40px 20px;
  position: fixed;
  z-index: 1;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  box-shadow: 20px;
  border-left: 1px solid #e5eaff;
  border-top: 1px solid #e5eaff;
  overflow-y: scroll;
  overflow-x: hidden;
}
.name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  text-transform: uppercase;
  color: #1c1c1c;
  margin-bottom: 10px;
}
.cmpgTtile {
  max-width: 318px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: #1c1c1c;
}
.cmpgDetails {
  max-width: 282px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #585757;
  margin-top: 17px;
}
