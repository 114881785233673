.topBannerParent {
  height: 207px;
  width: 100%;
  background-size: cover;
  font-family: "Inter";
  font-style: normal;
  color: #ffffff;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 20px;
}
.content {
  display: flex;
  flex-direction: column;
}
.breadcrumb {
  position: absolute;
  top: 20px;
  left: 20px;
}
.topBannerParent h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  color: #ffffff;
  font-family: "Inter";
  font-style: normal;
}
.topBannerParent p {
  color: rgba(255, 255, 255, 0.4);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.shareButton {
  padding: 10px 25px 10px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--supporting-color);
  border-radius: 30px;
  cursor: pointer;
}
.shareButton p {
  margin-left: 15px;
  color: #000000;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
