.pendingApprovalParent {
  font-family: "Inter";
  font-style: normal;
  color: #1c1c1c;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
.userId {
  height: 38px;
}
.nameDp {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
      0deg,
      rgba(0, 186, 52, 0.1),
      rgba(0, 186, 52, 0.1)
    ),
    #ffffff;

  color: #00ba34;
  margin-right: 12px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.pendingApprovalText {
  color: #f50000;
  display: flex;
  align-items: center;
}
.pendingApprovalTextPoint {
  margin-right: 5px;
  background: #fea5a8;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.reviewButton {
  padding: 10px 0;
  background: #097b15;
  border-radius: 8px;
  text-align: center;
  color: #ffffff;
  width: 138px;
}
.tableBottomLine {
  border: 1px solid #e5eaff;
}

/* onContest listing page  */
.pendingOnContestDetils {
  margin-top: 30px;
  color: #000000;
  font-family: "Inter";
  font-style: normal;
}
.pendingOnContestDetils h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
}
.pendingOnContestDetils p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.pendingListContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-top: 35px;
}
.approve {
  padding: 2px 10px;
  margin-left: auto;
  background: var(--supporting-color);
  border-radius: 6px;

  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  text-align: center;
  color: #3361ff;
  cursor: pointer;
}
.bottomBand {
  width: 100%;
  padding: 14px;
  background: var(--supporting-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
}
.bottomBand button {
  padding: 9px 16px;
  border-radius: 60px;
  color: #ffffff;
  background: var(--secondary-color);
}

.pendingNum {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #3361ff;
}

/* onContest listing page  */

@media (max-width: 768px) {
  .reviewButton {
    width: auto;
  }
  #hideInMobile {
    display: none;
  }
}
