.createPromotion {
  width: 100%;
  height: auto;
  padding: 20px;
}
.createPromotion h2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}
.createPromotion h3 {
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #000000;
}
.createPromotion p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}
.contentDiv {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: #ffffff;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: auto;
  border-bottom: 1px solid var(--supporting-color);
}

.headerLeft {
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerLeft img {
  padding: 10px;
  border: 1px solid var(--supporting-color);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 10px;
  margin-right: 16px;
}
.body {
  padding: 20px;
}

.buttonWrapper button {
  padding: 10px 4%;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
.buttonWrapper .discard {
  color: #344054;
  border: 1px solid var(--supporting-color);
}
.buttonWrapper .save {
  color: #ffffff;
  background: var(--primary-color);
}
.uploadButton {
  margin: 10px;
  padding: 5px;
  font-size: 14px;
  line-height: 24px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #ffffff;
  background: var(--primary-color);
}