.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}
.selectAdsPopup {
  width: 100%;
  height: 100vh;
  padding: 94px 30px 30px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content {
  width: 75%;
  height: 100%;
  border-radius: 16px;
  background: #ffffff;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: auto;
  border-bottom: 1px solid var(--supporting-color);
  position: sticky;
  top: 0;
  background: #ffffff;
}

.headerLeft {
  display: flex;
  align-items: center;
  justify-content: center;
}
.body {
  padding: 20px;
}
.buttonWrapper {
  background: #ffffff;
  width: 100%;
  height: 94px;
  position: sticky;
  border-top: 1px solid var(--supporting-color);
  bottom: 0;
  right: 0;
  padding: 32px 32px 18px 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  z-index: 1;
}
.buttonWrapper button {
  padding: 10px 4%;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
.buttonWrapper .discard {
  color: #344054;
  border: 1px solid var(--supporting-color);
}
.buttonWrapper .save {
  color: #ffffff;
  background: var(--primary-color);
}

.promotionListGrid {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 12px;
}

.promotionListGrid .entry {
  cursor: pointer;
  width: 100%;
  height: auto;
  border-radius: 10px;
  background: var(--supporting-color);
  position: relative;
}
.promotionListGrid img {
  height: 130px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}
.promotionListGrid p {
  font-weight: 600;
  font-size: 85%;
  line-height: 140%;
  color: #000000;
}
.editButton {
  padding: 5px 12px;
  background: #fd6b6b !important;
  border-radius: 60px;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #ffffff;
  white-space: nowrap;
  width: fit-content;
  align-self: flex-end;
}
