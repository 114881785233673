.analytics {
  padding: 10px;
}

.analytics h3 {
  color: #1c1c1c;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
}
.analytics h4 {
  color: #1c1c1c;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
}
.dropDown {
  display: flex;
  position: relative;
}
.dropDown h2 {
  font-weight: 500;
  font-size: 32px;
  line-height: 140%;
  color: #000000;
}
.dropDown img {
  margin-left: 12px;
  padding: 8px 12px;
  border: 1px solid var(--supporting-color);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}
.dropDownPopup {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.1), 0px 9px 46px rgba(0, 0, 0, 0.06),
    0px 24px 38px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  min-width: 200px;
  z-index: 1;
}
.dropDownPopup h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #777777;
  padding: 15px;
  cursor: pointer;
}
.dropDownPopup p {
  padding: 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  border-top: 1px solid var(--supporting-color);
  border-bottom: 1px solid var(--supporting-color);
}
