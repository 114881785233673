.subHdead {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
  margin-top: 20px;
  text-align: left;
}

.timetoup {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  left: 50%;
  background: rgba(17, 17, 17, 0.6);
  transform: translate(-50%, 0%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.declbutn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 20px;
  width: 100%;
  height: 51px;
  background: #111111;
  border-radius: 10px;
  margin: 46px auto;
}

.declbutn > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.imgWid {
  width: 24px;
  height: 24px;
  border-radius: 30px;
}

.eachgridds {
  width: 100%;
  height: 200px;
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  overflow: hidden;
}

.griding {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-top: 30px;
}

.stasuts {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 39px;
  padding: 20px;
  background: #cc0009;
  color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
}
.StatusClosed {
  background: #056b10;
}

.cpngstuts {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-left: 10px;
}
.open {
  display: flex;
  align-items: center;
  gap: 10px;
}

.drwBox {
  cursor: pointer;
  width: 100%;
  height: 236px;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 4px 10px rgba(17, 17, 17, 0.05);
  border-radius: 10px;
  margin-top: 20px;
  padding: 30px 20px;
}

.howdr {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #111111;
  margin-top: 31px;
}

.butn {
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 20px;
  width: max-content;
  height: 51px;
  background: #f6f6f6;
  border-radius: 10px;
  margin: 20px auto 0 auto;
}

.butn > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #111111;
}

.how {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #111111;
}

.pendingBox {
  cursor: pointer;
  width: 100%;
  height: 64px;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22.5px;
}

.circleOpt {
  width: 30px;
  height: 30px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  position: absolute;
  top: 1px;
}

.open > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #111111;
  opacity: 0.3;
}

.theSWICT {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 4px;
  width: 88px;
  height: 37px;
  border: 1px solid #0652fd;
  border-radius: 6px;
}

.numbp {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #111111;
}

.imgCirc {
  width: 24px;
  border-radius: 30px;
  height: 24px;
}

.flexme {
  display: flex;
}

.participts {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 33px;
}

.cloz {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.pending {
  position: relative;
  width: 100%;
  height: 84px;
  margin-top: 20px;
  padding: 16px 20px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pendingTxt {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  color: #ffffff;
}

.end {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  margin-top: 40px;
}

.butun {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;
  position: absolute;
  width: 78px;
  height: 37px;
  bottom: 10px;
  left: 50%;
  /* top: 50%; */
  background: #ffffff;
  border-radius: 10px;
  transform: translate(-50%, -0%);
}

.butun > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #000000;
}

.pendingsub {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  color: #000000;
}

.winBocx {
  width: 100%;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 4px 10px rgba(17, 17, 17, 0.05);
  border-radius: 10px;
  margin-top: 30px;
  padding: 30px 20px;
  position: relative;
}
.winBocx h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  margin-bottom: 12px;
}
.inTxt {
  font-family: "Inter";
  font-style: normal;
  padding: 0 5px;
  /* width: 318px; */
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #111111;
}

.winern {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 20px;
  width: 100%;
  height: 51px;
  background: var(--primary-color);
  border-radius: 10px;
  margin: 17px auto;
}

.winern > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.winnerInput {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 22px;
  gap: 8px;
  width: 54px;
  height: 43px;
  background: #f6f6f6;
  border-radius: 10px;
}

.outOfbOxc {
  padding: 30px 0px;
}
.outOfbOxc h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  margin-bottom: 12px;
}

.search {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 6px;
  width: 244px;
  height: 50px;
  border: 1px solid #111111;
  border-radius: 60px;
  font-size: 14px;
  line-height: 17px;
}
.search[type="number"] {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 6px;
  width: 244px;
  height: 50px;
  border: 1px solid #111111;
  border-radius: 60px;
  font-size: 14px;
  line-height: 17px;
  margin-top: 0;
}
.input {
  border: 1px solid #808080;
  padding: 16px;
  width: 80%;
  border-radius: 2px;

}
.addme {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15.5px 16px 15.5px 20px;
  gap: 6px;
  width: 98px;
  height: 50px;
  background: #0652fd;
  border-radius: 60px;
  cursor: pointer;
}

.addme > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.tropy {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 11px;
  margin-top: 30px;
}

.trophyPic {
  width: 25px;
}

.tropy > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #111111;
}

.result {
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #111111;
}

.declwine {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 20px;
  width: 100%;
  height: 51px;
  left: 16px;
  top: 908px;
  background: #111111;
  border-radius: 10px;
}

.declwine > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.choosed {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  gap: 2px;
  width: 100%;
  height: 52px;
  margin: 24px auto 16px auto;
  background: #f6f6f6;
  border-radius: 10px;
}

.proPikIfNo {
  width: 20px;
}
.declbutn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 20px;
  width: 100%;
  height: 51px;
  background: #111111;
  border-radius: 10px;
  cursor: pointer;
}

.rmvC {
  cursor: pointer;
  width: 16px;
}

.rmv {
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #3361ff;
}

.openbutton {
  display: none;
}
.winnerSelection {
  padding: 20px;
}
.winnerNotPrepared {
  padding: 20px;
  height: 100px;
  text-align: center;
}
.filterChips {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  margin-top: 33px;
  cursor: pointer;
}

.eachChips {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;

  width: max-content;
  height: 42px;
  background: var(--primary-color);
  border-radius: 40px;
}
.eachChips > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}
.eachChipsDark {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: max-content;
  height: 42px;
  background: var(--supporting-color);
  border-radius: 40px;
}
.eachChipsDark > p {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: max-content;
  height: 42px;
  color: #000000;
  border-radius: 40px;
}
.button {
  padding: 5px 24px;
  background: var(--supporting-color);
  color: var(--primary-color);
  border-radius: 30px;
}

@media (max-width: 960px) {
  .openbutton {
    display: inline;
  }
}
