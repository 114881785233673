.winnerSelectionParent {
  font-family: "Inter";
  font-style: normal;
  color: #1c1c1c;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
.tableBottomLine {
  border: 1px solid #e5eaff;
}

.userId {
  height: 38px;
}
.nameDp {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
      0deg,
      rgba(0, 186, 52, 0.1),
      rgba(0, 186, 52, 0.1)
    ),
    #ffffff;

  color: #00ba34;
  margin-right: 12px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.addButton {
  padding: 10px 0;
  background: #097b15;
  border-radius: 8px;
  text-align: center;
  color: #ffffff;
  width: 138px;
}
.removeButton {
  padding: 10px 0;
  background: rgba(9, 123, 21, 0.5);
  border-radius: 8px;
  text-align: center;
  color: #ffffff;
  width: 138px;
}
