.approve {
  display: flex;
  margin-left: auto;
  flex-direction: row;
  align-items: center;
  padding: 2px 10px;
  gap: 4px;
  width: 60px;
  height: 24px;
  background: linear-gradient(0deg, #e5eaff, #e5eaff), #ffffff;
  border-radius: 6px;
  flex: none;
  order: 0;
  flex-grow: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  text-align: center;
  color: #3361ff;
  cursor: pointer;
}
.pendingNum {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #3361ff;
  margin-top: 21px;
  cursor: pointer;
}
.nameDp {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
      0deg,
      rgba(0, 186, 52, 0.1),
      rgba(0, 186, 52, 0.1)
    ),
    #ffffff;

  color: #00ba34;
  margin-right: 12px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
