.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}

.selectAdsPopup {
  width: 100%;
  height: 100vh;
  padding: 94px 30px 30px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content {
  width: 75%;
  height: 100%;
  border-radius: 16px;
  background: #ffffff;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: auto;
  border-bottom: 1px solid var(--supporting-color);
}

.headerLeft {
  display: flex;
  align-items: center;
  justify-content: center;
}
.body {
  padding: 20px;
  margin-bottom: 135px;
  min-height: calc(100% - 135px);
  overflow-y: scroll;
  overflow-x: hidden;
}
.body h3 {
  font-family: "Inter";
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}
.body p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}
.buttonWrapper {
  background: #ffffff;
  width: 100%;
  height: 94px;
  position: absolute;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top: 1px solid var(--supporting-color);
  bottom: 0;
  right: 0;
  padding: 32px 32px 18px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  z-index: 1;
}
.buttonWrapper button {
  padding: 10px 4%;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
.buttonWrapper .discard {
  color: #344054;
  border: 1px solid var(--supporting-color);
}
.buttonWrapper .save {
  color: #ffffff;
  background: var(--primary-color);
}
.line {
  border-top: 1px solid var(--supporting-color);
  width: 100%;
  height: 1px;
  margin: 30px 0;
}
