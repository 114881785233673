.formAttributes-heading {
  background-color: rgb(27, 40, 53);
  color: #fff;
  font-weight: 600;
  border-radius: 8px 8px 0px 0px;
}

.formAttributes-grid .row1 {
  display: flex;
  flex-direction: row;
}

.formAttributes-grid {
  display: grid;
  padding: 24px;
  gap: 10px;
  width: 100%;
  border-radius: 0px 0px 8px 8px;
  background-color: #fff;
  grid-template-areas: auto auto;
}

.formAttributes-grid input {
  border-radius: 4px;
  width: 100%;
  background-color: #e3e9ff;
  outline-style: solid;
  outline-color: #787878;
  outline-width: 1px;
  padding-left: 12px;
  line-height: 32px;
  font-size: 15px;
}

.formAttributes-grid p {
  width: 100%;
  max-width: 120px;
  color: #787878;
}
