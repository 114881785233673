.griding {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 14px;
  row-gap: 10px;
  margin-top: 18px;
}
.fourColumnGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 14px;
  row-gap: 10px;
  margin-top: 18px;
}

.oneGrid {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 14px;
  gap: 8px;
  width: 100%;
  min-height: 84px;
  background: var(--supporting-color);
  border-radius: 10px;
}
.oneGridHead {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1c1c1c;
}
.howMuch {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: left;
}
