.fileUpload {
  background: var(--secondary-color);
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.promotionListGrid {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 12px;
}

.promotionListGrid .entry {
  cursor: pointer;
  width: 100%;
  height: 216px;
  border-radius: 10px;
  background: var(--supporting-color);
}
.promotionListGrid img {
  height: 130px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}
.promotionListGrid p {
  font-weight: 600;
  font-size: 85%;
  line-height: 140%;
  color: #000000;
  padding: 10px;
}
