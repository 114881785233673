@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: 'Inter', sans-serif;

  --primary-color: #3361ff;
  --secondary-color: #99b0fd;
  --supporting-color: #e5eaff;
}

code {
  font-family: 'Inter', sans-serif;
}
