.leadsTable {
  width: 100%;
  text-align: left;
}
.leadsTable th,
.leadsTable td {
  padding: 14px;
}
.leadsTable thead {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #787878;
}
.leadsTable tbody {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #585757;
  cursor: pointer;
}
.leadsTable th {
  white-space: nowrap;
  border: 1px solid var(--supporting-color);
}
.leadsTable td {
  border-right: 1px solid var(--supporting-color);
}

.leadsTable tbody tr {
  border-bottom: 1px solid var(--supporting-color);
}
.slno {
  white-space: nowrap;
}
.name {
  white-space: nowrap;
  display: flex;
  gap: 8px;
}
.email {
  white-space: nowrap;
}
.email p {
  padding: 4px 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #000000;
  background: var(--supporting-color);
  border-radius: 40px;
  text-align: center;
}
.button {
  padding: 5px 24px;
  background: var(--supporting-color);
  color: var(--primary-color);
  border-radius: 30px;
}
