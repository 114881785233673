.reactPlayer {
  overflow: hidden;
  object-fit: contain;
}

.tagLine {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  margin-top: 10px;
}
.button {
  
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 120%;
  margin-top: 10px;
}
