.signupPage {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(221, 221, 221, 0.8);
  justify-content: center;
  color: #000000;
  text-align: center;
  max-width: 400px;
  filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04))
    drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
}
.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 20px 0 20px;
  margin-bottom: 32px;
}
.mobileLogin {
  width: 100%;
}
.mobileLogin input {
  width: 100%;
  height: 52px;
  background: #eeeeee;
  border-radius: 8px;
  border: none;
  padding: 13px;
}
.mobileLogin input[type="checkbox"] {
  width: 24px;
  height: 24px;
  accent-color: #00a989;
  color: #ffffff;
}
.phoneInput {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.country {
  border-right: 1px solid #c1c0c0;
  border-radius: 8px 0 0 8px;
  margin-bottom: 16px;
  width: 30%;
  height: 52px;
  background: #eeeeee;
  padding: 13px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.phoneInput input {
  border: none;
  margin: 0;
  padding-right: 0;
  font-size: 16px;
  width: 100%;
  border-radius: 0 8px 8px 0;
}

.phoneInput input:first-child {
  width: 20%;
  border-radius: 0;
  padding-left: 5px;
}
.button {
  width: 50%;
  padding: 16px 0;
  border-radius: 8px;
  color: #ffffff;
}
.button[disabled] {
  opacity: 60%;
  cursor: not-allowed;
}

.dropdownList {
  position: absolute;
  top: calc(100% - 16px);
  left: 0;
  width: 100%;
  background-color: #fff;
  filter: drop-shadow(rgba(0, 0, 0, 0.4) 2px 2px 2px);
  border-radius: 0 0 8px 8px;
  max-height: 150px; /* Adjust the max-height as needed */
  overflow-y: auto;
  text-align: left;
}

/* Style each dropdown item */
.dropdownList > div {
  padding: 10px;
  cursor: pointer;
}

/* Highlight the selected item */
.dropdownList > div:hover {
  background-color: #f0f0f0;
}
