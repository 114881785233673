.reactPlayer {
  overflow: hidden;
  object-fit: contain;
}
.heading {
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.description {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 120%;
  margin-bottom: 12px;
}
