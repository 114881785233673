.container {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}
.parentDiv {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background: rgba(277, 277, 277, 0.1);
  -webkit-backdrop-filter: blur(17px);
  backdrop-filter: blur(17px);  
  padding: 20px;
  transition: all 0.2s ease-in;
  opacity: 0;
}
.enter {
  opacity: 1;
}

.carouselContainer {
  position: relative;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.carouselSlide {
  display: flex;
  gap: 10px;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}
.active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.fadeOut {
  opacity: 0;
}
.carouselSlide::-webkit-scrollbar {
  display: none;
}

.carouselSlide img {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  scroll-snap-align: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.carouselSlide img.active {
  opacity: 1;
}
.button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 100%;
  border: none;
  background-color: transparent;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  opacity: 100%;
}
.button[disabled] {
  opacity: 0%;
  cursor: not-allowed;
}
