.filterParent {
  width: fit-content;
  padding: 8px 12px;
  /* border: 1px solid var(--supporting-color); */
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  display: flex;
  position: relative;
  cursor: pointer;
}
.filterSelected {
  background: var(--supporting-color);
}
.dropDownPopup {
  cursor: pointer;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.1), 0px 9px 46px rgba(0, 0, 0, 0.06),
    0px 24px 38px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  min-width: 200px;
  z-index: 1;
}
.dropDownPopup h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #777777;
  padding: 15px;
  cursor: pointer;
}
.dropDownPopup p {
  padding: 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  border-top: 1px solid var(--supporting-color);
  border-bottom: 1px solid var(--supporting-color);
}
