.filterChips {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    margin-top: 33px;
    cursor: pointer;
  }
  
  .eachChips {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
  
    width: max-content;
    height: 42px;
    background: var(--primary-color);
    border-radius: 40px;
  }
  .eachChips > p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
  }
  .eachChipsDark {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    width: max-content;
    height: 42px;
    background: var(--supporting-color);
    border-radius: 40px;
  }
  .eachChipsDark > p {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    width: max-content;
    height: 42px;
    color: #000000;
    border-radius: 40px;
  }