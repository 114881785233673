.contestDetails h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
}
.button {
  padding: 5px 24px;
  background: var(--supporting-color);
  color: var(--primary-color);
  border-radius: 30px;
  white-space: nowrap;
}
