.headerContainer {
  display: flex;
  gap: 16px;
  margin: 0 24px 24px 24px;
}

.headerPara {
  margin: 0px;
  max-width: 432px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #787878;
}

.popUpContainer {
  width: 544px;
  margin: 0;
  background-color: white;
  border-radius: 16px 16px;
}

.popUpHeader {
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 4px;
}

.buttonContainer {
  margin: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
}

.buttonSpan {
  display: inline-block;
  padding: 10px 18px 10px 18px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
}

.buttonSpanWhite {
  display: inline-block;
  padding: 10px 18px 10px 18px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #eaeaea;
  cursor: pointer;
}

.button {
  background: #ffffff;
  border: 1px solid #c7c8ca;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.deleteButton {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.popUpImg {
  align-self: flex-start;
}

.textarea {
  color: #1c1c1c;
  width: 100%;
  background: #cdcdcd;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  padding: 12px;
  outline: none;
  resize: none;
}
