.form {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 20px;
  margin: 12px 0;
}
.form input,
textarea {
  border: 1px solid var(--secondary-color);
  width: 100%;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.form input:read-only {
  border: none;
  border-bottom: 1px solid var(--secondary-color);
}
.heading {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.button {
  padding: 5px 24px;
  background: var(--supporting-color);
  border-radius: 30px;
  cursor: pointer;
}
