.navBar {
  position: fixed;
  z-index: 2;

  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(35px);
  padding: 16px 16px 16px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 72px;
}
.bel {
  display: flex;
}
.notification {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  width: 19.86px;
  height: 19.86px;
  background: #e92c2c;
  margin-left: -13px;
  border-radius: 96px;
  color: #ffffff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
}
.logText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #1c1c1c;
}
.searchBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0px 8px 10px;
  gap: 4px;
  isolation: isolate;
  width: 316px;
  height: 36px;
  background: var(--supporting-color);
  border-radius: 8px;
}
.searchBox > input::placeholder {
  color: black;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.dropDown {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  gap: 14px;
  height: 40px;
  /* background: #f6f6f6; */
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  cursor: pointer;
}

.prodropdown {
  width: 32px;
  height: 32px;
  border-radius: 30px;
}

.option {
  position: absolute;
  right: -20px;
  top: 55px;
  padding: 10px 0;
  gap: 8px;
  width: 300px;
  max-height: 70vh;
  overflow-y: scroll;
  background: #ffffff;
  box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.1), 0px 9px 46px rgba(0, 0, 0, 0.06),
    0px 24px 38px rgba(0, 0, 0, 0.04);
  border-radius: 0 0 8px 8px;

  -webkit-border-radius: 0 0 8px 8px;
  -moz-border-radius: 0 0 8px 8px;
  -ms-border-radius: 0 0 8px 8px;
  -o-border-radius: 0 0 8px 8px;
}
.option h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #777777;
  padding: 15px;
  cursor: pointer;
}
.option li {
  border-top: 1px solid var(--supporting-color);
  color: #000000;
}
.option li:first-child {
  border: none;
}
.option li:last-child {
  border-bottom: 1px solid var(--supporting-color);
}
.dropDown,
.option p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 15px;
}
.option p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 15px;
  cursor: pointer;
}

.openbutton {
  display: none;
}
.closebutton {
  display: none;
}
@media (max-width: 960px) {
  .openbutton {
    display: inline;
  }
  .closebutton {
    display: inline;
  }
}
