.createAdd {
  width: 100%;
  height: 100vh;
  padding: 94px 30px 30px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.createAdd h2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}

.createAdd p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}
.contentDiv {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: #ffffff;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: auto;
  border-bottom: 1px solid var(--supporting-color);
}

.headerLeft {
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerLeft img {
  padding: 10px;
  border: 1px solid var(--supporting-color);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 10px;
  margin-right: 16px;
}
.body {
  padding: 0 10px 20px 10px;
}
.buttonWrapper {
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
}
.buttonWrapper button {
  padding: 10px 4%;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
.buttonWrapper .discard {
  color: #344054;
  border: 1px solid var(--supporting-color);
}
.buttonWrapper .save {
  color: #ffffff;
  background: var(--primary-color);
}

.form {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 20px;
  margin: 8px 0;
}
.form input {
  border: 1px solid var(--secondary-color);
  width: 100%;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.form input:focus {
  border: 2px solid var(--secondary-color);
}

.form .inputError {
  border: 1px solid rgb(255, 41, 41);
}
.error {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: end;
}
