.sidebar {
  height: 100%;
  width: 276px;
  position: relative;
  padding: 33px 10px 0 24px;
  opacity: 1;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  /* padding-top: 60px; */
  box-shadow: 20px;
  /* border-right: 1px solid #e5eaff; */
  border-top: 1px solid #e5eaff;
}
.fixedDivSidebar {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 75px;
  padding-bottom: 30px;
  height: calc(100% - 75px);
  max-width: 276px;
  overflow-y: scroll;
  overflow-x: hidden;
  border-right: 1px solid #e5eaff;
  background: #ffffff;
  /* z-index: 1; */
}
.icon {
  width: 24px;
}
.withSubMenu {
  margin: 0px 10px 0 24px;
  padding: 7px 10px;
  border-radius: 8px;
  background: white;
  /* min-height: 44px; */
  color: #787878;
  cursor: pointer;
}
.withSubmenuActive {
  margin: 0px 10px 0 24px;
  cursor: pointer;
  padding: 7px 10px;
  border-radius: 8px;
  background: var(--supporting-color);
  color: #000000;
}
.oneMenu {
  margin: 0px 10px 0 24px;
  display: flex;
  padding: 15px 10px;
  justify-content: flex-start;
  gap: 15px;
  align-items: center;
  border-radius: 8px;
  background: white;
  width: 242px;
  min-height: 44px;
  color: #787878;
  cursor: pointer;
}
.oneMenuActive {
  display: flex;
  padding: 15px 10px;
  justify-content: flex-start;
  gap: 15px;
  align-items: center;
  border-radius: 8px;
  background: var(--supporting-color);
  color: #000000;
  cursor: pointer;
}
.oneMenu > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #787878;
}
.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}
/* .sidebar a:hover {
    color: white;
    background-color: black;
  } */

.closebutton {
  display: none;
}
.openbutton {
  display: none;
}

@media (max-width: 960px) {
  .sidebar {
    width: 0px;
    padding: 0;
    opacity: 0;
  }
  .openbutton {
    display: inline;
  }
  .closebutton {
    display: inline;
  }
}
@media (min-width: 960px) {
  .sidebar {
    max-width: 300px;
    opacity: 1;
  }
}
