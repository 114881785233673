.outLine {
  padding: 15px 16px;
  margin-top: 15px;
}

.breadcrump {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
}

.numbp {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #111111;
}

.flexme {
  display: flex;
}

.imgCirc {
  width: 24px;
  border-radius: 30px;
  height: 24px;
}

.open {
  display: flex;
  align-items: center;
  gap: 10px;
}

.open > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #111111;
  opacity: 0.3;
}

.circleOpt {
  width: 30px;
  height: 30px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  position: absolute;
  top: 1px;
}

.cpngstuts {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-left: 10px;
}

.theSWICT {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 4px;
  width: 88px;
  height: 37px;
  border: 1px solid #0652fd;
  border-radius: 6px;
}

.stasuts {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 39px;
  padding: 20px;
  background: #CC0009;
  color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
}
.StatusClosed {
  background: #056B10;
  
}
.participts {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 33px;
}

.switch {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 22px 10px 20px;
  gap: 16px;
  background: #0745fb;
  box-shadow: 0px 4px 30px rgba(7, 69, 251, 0.3);
  border-radius: 10px;
  width: max-content;
  height: 42px;
  margin-left: auto;
}

.switch > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

.filterChips {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  margin-top: 33px;
  cursor: pointer;
  /* overflow-x: auto;   */
}
/* .filterChips::-webkit-scrollbar {
  display: none;
} */

.eachChips {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;

  width: max-content;
  height: 42px;
  background: var(--primary-color);
  border-radius: 40px;
}
.eachChips > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}
.eachChipsDark {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;

  width: max-content;
  height: 42px;
  background: var(--supporting-color);
  border-radius: 40px;
}
.eachChipsDark > p {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;

  width: max-content;
  height: 42px;
  color: #000000;
  border-radius: 40px;
}

.griding {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-top: 44px;
}
.button {
  padding: 5px 24px;
  background: var(--supporting-color);
  color: var(--primary-color);
  border-radius: 30px;
}
