.main{
  transition: margin-left .5s;
}
.outLine {
  z-index: 0;
  padding: 15px 16px;
  margin-top: 15px;
}
.switch {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 22px 10px 20px;
  gap: 16px;
  background: #0745fb;
  box-shadow: 0px 4px 30px rgba(7, 69, 251, 0.3);
  border-radius: 10px;
  width: 215px;
  height: 42px;
  margin-left: auto;
}
.switch > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}
.filterChips {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  margin-top: 33px;
  cursor: pointer;
}

.eachChips {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;

  width: max-content;
  height: 42px;
  background: #000000;
  border-radius: 40px;
}
.eachChips > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}
.eachChipsDark {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;

  width: max-content;
  height: 42px;
  background: #f6f6f6;
  border-radius: 40px;
}
.eachChipsDark > p {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;

  width: max-content;
  height: 42px;
  color: #000000;
  border-radius: 40px;
}
.griding {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  margin-top: 44px;
}

.sidebar {
  height: 100%;
  width: 300px;
  position: fixed;
  z-index: 1;
  top: 94px;
  left: 0;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  /* padding-top: 60px; */
  box-shadow: 20px;
}

.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}
/* .sidebar a:hover {
  color: white;
  background-color: black;
} */

.closebutton{
  display: none;
}
.openbutton{
  display: none;
}

@media (max-width : 960px) {
  .sidebar {
    width: 0px;
  }
  .openbutton{
    display: inline;
  }
  .closebutton{
    display: inline;
  }
}
@media (min-width : 960px) {
  .sidebar {
    width: 300px;
  }
}