.card {
  padding: 15px 20px;
  width: 100%;
  /* height: 171px; */
  background: var(--supporting-color);
  border-radius: 10px;
  cursor: pointer;
  position: relative;
}
.card h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  text-align: left;
}
.card button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 7px;
  width: max-content;
  background: var(--primary-color);
  border-radius: 50px;
  cursor: pointer;
  margin-left: auto;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #ffffff;
}
