.holoOption {
  width: 32px;
  height: 32px;
  background: var(--secondary-color);
  border-radius: 48px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dotInLeads {
  width: 32px;
  height: 32px;
  border-radius: 48px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bigDropme {
  width: 211px;
  background: #ffffff;
  box-shadow: 0px 11px 15px rgb(0 0 0 / 10%), 0px 9px 46px rgb(0 0 0 / 6%),
    0px 24px 38px rgb(0 0 0 / 4%);
  border-radius: 8px;
  position: absolute;
  right: 56px;
  top: 30px;
  z-index: 11;
  padding: 16px;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
  -moz-transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
  -ms-transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
  -o-transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
}
