* {
  margin: 0;
  padding: 0;
}
p {
  cursor: default;
}
.App {
  /* background-color: #171717;
  background-image: url("./assests/background3.svg"); */
  /* max-width: 1280px; */
  margin-left: auto;
  margin-right: auto;
}
/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.tempButton {
  padding: 10px 16px;
  border-radius: 54px;
  border: 1px solid #4e04c6;
  background: #4e04c6;
  color: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.24);
  white-space: nowrap;
}
/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* custom scollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.seperator-black {
  border: 1px solid rgba(89, 89, 89, 0.1);
  width: 100%;
  height: 0px;
  border-radius: 100%;
}
button:disabled {
  opacity: 0.4;
}
input:focus {
  /* border: 1px solid rgb(0, 0, 0); */
  outline-style: none !important;
}
textarea:focus {
  /* border: 1px solid rgb(0, 0, 0); */
  outline-style: none !important;
}
select:focus {
  outline-style: none !important;
}
.timer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 8px 6px 6px;
  gap: 10px;
  padding: 8px;
  position: absolute;
  width: max-content;
  height: 28px;
  right: 20px;
  top: 20px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 30px;
}
.text-large-bold {
  font-family: var(--fontOne);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
}
.text-regular-bold {
  font-family: var(--fontOne);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}
.text-regular {
  font-family: var(--fontOne);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
.text-smallest {
  font-size: 12px;
  font-family: var(--font-primary);
  font-weight: 500;
  line-height: 16px;
}
.text-large-clash {
  font-size: 21px;
  font-family: var(--font-secondary);
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.42px;
}
.text_Ell {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.timer > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
}

.helpController {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  background-color: black;
}

.fifaHead {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #1c1c1c;
}

.flexio {
  display: flex;
  align-items: center;
}

.uk-dropdown.uk-open {
  display: block;
  max-width: 100% !important;
  top: 52.6667px !important;
  left: 0 !important;
  height: max-content !important;
}

.help {
  transition: 0.2s;
  bottom: 1.2rem;
  right: 1.5rem;
  z-index: 11;
  position: fixed;
}

.thegrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.secondry-button {
  padding: 5px 24px;
  background: var(--supporting-color);
  color: var(--primary-color);
  border-radius: 30px;
}
.commonButton {
  padding: 9px 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  background: var(--secondary-color);
}
.commonButtonNotSelected {
  padding: 9px 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  border: 1px solid var(--secondary-color);
}
.flexico {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.absolutePopUp {
  position: absolute;
  z-index: 100;
  background: white;
}
.mainCntrPge {
  width: 100%;
  margin-left: 30px;
  margin-right: auto;
  background: #ffffff;
  backdrop-filter: blur(50px);
}
.mobWidth {
  width: 100%;
  max-width: 778px;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
}
.paddingx {
  padding: 0 16px;
}
.desktopWidthController {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
}

.fullWidthController {
  max-width: 100%;
}

.widthController {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  min-height: 100vh;
  background-color: black;
}

.noDesk {
  display: none;
}
.button-common {
  padding: 5px 24px;
  background: var(--supporting-color);
  color: var(--primary-color);
  border-radius: 30px;
}

/* .container{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  
} */
.yesToMobile {
  display: none;
}

.three {
  font-weight: 300;
}
.four {
  font-weight: 400;
}
.five {
  font-weight: 500;
}
.six {
  font-weight: 600;
}
.left-sidebar {
  grid-area: left-sidebar;
}
.content {
  grid-area: content;
}
.right-sidebar {
  grid-area: right-sidebar;
}
.center {
  grid-area: center;
}
.wrapper {
  display: grid;
  grid-template-columns: 276px 4fr;
  grid-template-areas: "left-sidebar content";
  padding-top: 72px;
}
.content-wrapper {
  display: grid;
  grid-template-columns: 4fr 2fr;
  grid-template-areas: "center right-sidebar ";
  padding-bottom: 50px;
}
.text-large {
  font-family: var(--fontOne);
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
}
.text-regular-bold {
  font-family: var(--fontOne);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}
.text-regular {
  font-family: var(--fontOne);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
.text-large-medium {
  font-family: var(--fontOne);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
}
.text-regular-medium {
  font-family: var(--fontOne);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.text-regular-callout {
  font-family: var(--fontOne);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
.text-small {
  font-family: var(--fontOne);
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}

.searchBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0px 8px 10px;
  gap: 4px;
  isolation: isolate;
  width: 316px;
  height: 36px;
  background: var(--supporting-color);
  border-radius: 8px;
}
.searchBox > input::placeholder {
  color: black;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
@media screen and (min-device-width: 481px) and (max-device-width: 864px) {
  .help {
    transition: 0.2s;
    bottom: 1.2rem;
    right: 1.5rem;
    z-index: 11;
    position: fixed;
  }
}

@media screen and (min-device-width: 864px) and (max-device-width: 1024px) {
  .help {
    transition: 0.2s;
    bottom: 8rem;
    right: 7.5rem;
    z-index: 11;
    position: fixed;
  }
}

@media screen and (max-width: 480px) {
  .wrapper {
    grid-template-areas: " content";
  }
  .help {
    transition: 0.2s;
    bottom: 5.2rem;
    right: 1.5rem;
    z-index: 11;
    position: fixed;
  }

  .noMobie {
    display: none;
  }

  .flexico {
    flex-direction: column;
  }

  .noMob {
    display: none;
  }

  .noDesk {
    display: block;
  }

  .yesToMobile {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    padding-top: 0px;
  }
}

@media screen and (min-device-width: 481px) and (max-device-width: 1024px) {
  .flexico {
    flex-direction: column;
  }

  .noDesk {
    display: block;
  }

  .noMobie {
    display: flex;
  }

  .noMob {
  }
}
