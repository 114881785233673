.parent {
  width: 100%;
  height: 100vh;
  padding: 94px 30px 30px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.parent h2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}
.parent h3 {
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #000000;
}
.parent p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: auto;
  border-bottom: 1px solid var(--supporting-color);
}

.headerLeft {
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerLeft img {
  padding: 10px;
  border: 1px solid var(--supporting-color);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 10px;
  margin-right: 16px;
}
.contentDiv {
  width: 70%;
  height: 100%;
  border-radius: 16px;
  background: #ffffff;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.body {
  padding: 20px;
}
.body button {
  padding: 5px 12px;
  background: #fd6b6b !important;
  border-radius: 60px;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #ffffff;
  white-space: nowrap;
  width: fit-content;
}
.form {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 20px;
  margin: 8px 0;
}
.form input,
textarea {
  border: 1px solid var(--secondary-color);
  width: 100%;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.form textarea {
  min-height: 100px;
  resize: none;
  overflow: auto;
}

.form input:focus,
textarea:focus {
  border: 2px solid var(--secondary-color);
}
.form .inputError {
  border: 1px solid rgb(255, 41, 41);
}

.buttonWrapper {
  background: #ffffff;
  width: 100%;
  height: 94px;
  position: sticky;
  border-top: 1px solid var(--supporting-color);
  bottom: 0;
  right: 0;
  padding: 32px 32px 18px 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  z-index: 1;
}
.buttonWrapper button {
  padding: 10px 4%;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
.dropDown {
  width: fit-content;
  padding: 8px 12px;
  /* border: 1px solid var(--supporting-color); */
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  border: 1px solid var(--secondary-color);
  display: flex;
  position: relative;
  cursor: pointer;
}
