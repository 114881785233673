.card {
  padding: 16px;
  /* height: 266px; */
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  background: var(--supporting-color);
  color: #000000;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.timerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.timer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 8px 6px 6px;
  gap: 10px;
  height: 28px;
  background: rgba(249, 249, 249, 0.5);
  backdrop-filter: blur(20px);
  border-radius: 30px;
}

.timer > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
}

.text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  text-align: center;
}

.campaingn {
  font-family: "Inter";
  font-weight: 600;
  font-size: 21px;
  line-height: 140%;

  color: #000000;
}

.date {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #111111;
  margin-bottom: 2px;
  text-align: left;
}
.buttonWrapper {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 5px;
  margin-top: 30px;
}
.button {
  padding: 10px 16px;

  background: var(--primary-color);
  border-radius: 50px;
  cursor: pointer;
}

.button > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #ffffff;
}

.capmme {
  margin-top: 16px;
  display: grid;
  grid-template-columns: auto auto;
}

.part {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
}

.flexmo {
  display: flex;
  align-items: center;
  cursor: default;
  justify-content: flex-start;
}
.propik:first-child {
  margin-left: 0px;
}
.propik {
  margin-left: -10px;
  width: 32px;
  height: 32px;
  border: 2px solid #ffffff;
  border-radius: 256px;
  object-position: top;
  object-fit: cover;
}
.totlaXtra {
  margin-top: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--primary-color);
  cursor: default;
}
