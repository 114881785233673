.popUpContainer {
  width: 544px;
  margin: 0;
  background-color: white;
  border-radius: 16px;
}
.content {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content input {
  border-radius: 16px;
  padding: 5px;
  border: 1px solid var(--secondary-color);
}
.button {
  background: var(--primary-color);
  color: #fff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px 20px;
}
.button:disabled {
  opacity: 50%;
}
