.wrapper {
  /* max-width: 610px; */
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  background: #fff;
  box-shadow: -4px 0px 44px 0px rgba(0, 0, 0, 0.1);
}

.heading1 {
  font-size: 32px;
  font-family: var(--font-secondary);
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.42px;
}
.desciption1 {
  font-size: 17px;
  font-family: var(--font-primary);
  font-weight: 500 !important;
  line-height: 20px;
}
.form .desciption2 {
  font-size: 16px;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.form h4 {
  font-size: 21px;
  font-family: var(--font-secondary);
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.42px;
  color: #000;
}
.form .note {
  color: #787878;
  font-size: 12px;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  justify-content: end;
  width: 100%;
  margin-top: 10px;
}

.form p,
label {
  font-size: 15px;
  font-family: var(--font-primary);
  font-weight: 600;
  line-height: 20px;
}
.form input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  outline: 2px solid #4e04c6;
  outline-offset: 3px;
  background-color: transparent; 
  margin-right: 12px; /* Adjust as needed */
}

/* Custom style for the checked state */
.form input[type="radio"]:checked {
  background-color: #4e04c6;
}

.form input[type="text"],
input[type="number"],
.form textarea {
  border-radius: 8px;
  margin-top: 12px;
  border: 1px solid #dedede;
  background: #fff;
  padding: 16px 12px;
  font-size: 14px;
  font-family: var(--font-primary);
  font-weight: 500;
  line-height: 20px;
  width: 100%;
}
.form textarea {
  min-height: 100px;
}
.form input:focus,
textarea:focus {
  border: 1px solid #4e04c6;
}

.form .inputError,
.form .inputError {
  border: 1px solid #ff9696 !important;
}
.form .error {
  color: #ff4343;
  font-size: 12px;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 10px;
}
.form button {
  padding: 10px 16px;
  border-radius: 54px;
  border: 1px solid #4e04c6;
  background: #4e04c6;
  color: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.24);
  white-space: nowrap;
  font-size: 14px;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.selectOrgButton {
  border-radius: 54px;
  background: rgba(78, 4, 198, 0.1);
  border: none;
  cursor: pointer;
  padding: 6px 16px;
  color: #4e04c6;
}
.selectedOrgButton {
  border: 1px solid #4e04c6;
}
.clearSelectionParent {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.clearSelectionInMobile {
  display: inline;
  color: #1b67fa;
  font-size: 12px !important;
  font-family: var(--font-primary);
  font-weight: 500;
  line-height: 16px;
}
.packWrapper {
  /* "mt-[20px] gap-[10px] flex md:flex-wrap overflow-auto" */
  margin-top: 20px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  overflow: auto;
}
.packWrapper .packDescription {
  color: #777;
  font-size: 12px;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.packWrapper .packFeatures {
  font-size: 12px;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

@media (max-width: 768px) {
  .wrapper {
    padding: 20px;
  }
  .heading1 {
    font-size: 21px;
    font-family: var(--font-secondary);
    font-weight: 600;
    letter-spacing: 0.64px;
  }
  .desciption1 {
    font-size: 14px;
    font-family: var(--font-primary);
    font-weight: 500;
    line-height: 24px;
  }
  .form h4 {
    font-size: 21px;
    line-height: 20px;
    letter-spacing: 0.36px;
  }
  .form p {
    font-size: 14px;
    font-weight: 500;
  }
  .packWrapper {
    flex-wrap: nowrap;
  }
}
