.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.line {
  border-top: 1px solid var(--supporting-color);
  width: 100%;
}
.popup {
  width: 100%;
  height: 100vh;
  padding: 94px 30px 30px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content {
  width: 50%;
  height: 100%;
  border-radius: 16px;
  background: #ffffff;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: auto;
  border-bottom: 1px solid var(--supporting-color);
}

.headerLeft {
  display: flex;
  align-items: center;
  justify-content: center;
}
.body {
  padding: 0 20px 20px 20px;
}
.buttonWrapper {
  background: #ffffff;
  width: 100%;
  height: 94px;
  position: sticky;
  border-top: 1px solid var(--supporting-color);
  bottom: 0;
  right: 0;
  padding: 32px 32px 18px 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  z-index: 1;
}
.wrapper button {
  padding: 10px 4%;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
.wrapper .discard {
  color: #344054;
  border: 1px solid var(--supporting-color);
}
.wrapper .save {
  color: #ffffff;
  background: var(--primary-color);
}

.form {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 20px;
  margin: 8px 0;
}
.form input,
textarea {
  border: 1px solid var(--secondary-color);
  width: 100%;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.form textarea {
  min-height: 100px;
  resize: none;
  overflow: auto;
}

.form input:focus,
textarea:focus {
  border: 2px solid var(--secondary-color);
}
.form .inputError {
  border: 1px solid rgb(255, 41, 41);
}
.error {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: end;
}
.search {
  padding: 10px 4%;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #ffffff;
  background: var(--primary-color);
}
