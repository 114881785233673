.editCampaign {
  width: 100%;
  height: 100vh;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.editCampaign h2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}
.editCampaign h3 {
  font-family: "Inter";
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}
.editCampaign p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}
.contentDiv {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: #ffffff;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: auto;
  border-bottom: 1px solid var(--supporting-color);
}

.headerLeft {
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerLeft img {
  padding: 10px;
  border: 1px solid var(--supporting-color);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 10px;
  margin-right: 16px;
}

.body {
  display: grid;
  grid-template-columns: 1fr;
  height: 82%;
}

.left {
  border-right: 1px solid var(--supporting-color);
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 20px;
}
.right {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 20px 20px 0 20px;
  position: relative;
}

/* CamapignEditForm */
.form {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 20px;
  margin: 12px 0;
}
.changeImageButton {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 6px 16px;
  color: #000000;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  background: var(--supporting-color);
  border-radius: 30px;
}

.line {
  border-top: 1px solid var(--supporting-color);
  width: 100%;
}
.form input,
textarea {
  border: 1px solid var(--secondary-color);
  width: 100%;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.form input[type="color"] {
  width: 50px;
  height: 50px;
}
.form textarea {
  min-height: 100px;
  resize: none;
  overflow: auto;
}

.form input:focus,
textarea:focus {
  border: 2px solid var(--secondary-color);
}
.form .inputError {
  border: 1px solid rgb(255, 41, 41);
}
.error {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: end;
}
/* CamapignEditForm */

/* Campaign Editing right side contest list  */

.contest {
  width: 100%;
  border: 1px solid var(--secondary-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px;
  border-radius: 12px;
  margin-top: 16px;
}
.contest img {
  margin-right: 14px;
}
.contest p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}
.contest .removeButton {
  font-weight: 600;
  color: #b42318;
  margin-left: auto;
}
.contest .addButton {
  font-weight: 600;
  color: #02ab13;
  margin-left: auto;
}
/* Campaign Editing right side contest list  */

/* handle next button  */
.buttonWrapper {
  background: #ffffff;
  width: 100%;
  height: 94px;
  position: sticky;
  border-top: 1px solid var(--supporting-color);
  bottom: 0;
  right: 0;
  padding: 32px 32px 18px 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  z-index: 1;
}
.buttonWrapper button {
  padding: 10px 4%;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
.buttonWrapper .discard {
  color: #344054;
  border: 1px solid var(--supporting-color);
}
.buttonWrapper .save {
  color: #ffffff;
  background: var(--primary-color);
}
/* handle next button  */

/* Contest Edit Contest list  */
.contestEditGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
}
.contestEditGrid .contestInContestEdit {
  cursor: pointer;
  width: 100%;
  height: 216px;
  border-radius: 10px;
  background: var(--supporting-color);
}
.contestEditGrid .selectedContestInContestEdit {
  border: 2px solid var(--secondary-color);
}
.contestEditGrid img {
  height: 130px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}
.contestEditGrid p {
  font-weight: 600;
  font-size: 85%;
  line-height: 140%;
  color: #000000;
  padding: 10px;
}

/* Contest Edit Contest list  */
.formAttrWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.formAttr {
  width: 100%;
  height: 100vh;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addNew {
  padding: 8px 12px;
  background: var(--supporting-color);
  border-radius: 12px;
}
