.flexmo {
  display: flex;
  align-items: center;
  cursor: default;
  justify-content: flex-start;
}
.propik:first-child {
  margin-left: 0px;
}
.propik {
  margin-left: -10px;
  width: 32px;
  height: 32px;
  border: 2px solid #ffffff;
  border-radius: 256px;
  object-position: top;
  object-fit: cover;
}
.totlaXtra {
  margin-top: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--primary-color);
  cursor: default;
}
