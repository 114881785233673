.container {
  height: 100%;
  width: 100%;
  padding: 20px;
}
.grid {
  height: 100%;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  overflow: hidden;
}
.grid button {
  padding: 10px 25px;
  background: #06b818;
  color: #ffffff;
  border-radius: 10px;
}
.grid button:disabled {
  opacity: 30%;
}
.boxHead {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 20px;
  height: calc(100% - 108px);
  width: 100%;
  overflow: hidden;
}
.table {
  width: 100%;
}
.table td {
  padding: 10px;
}
