.shareBox {
  width: 358px;
  height: 328px;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 4px 10px rgb(17 17 17 / 5%);
  border-radius: 10px;
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 28px;
}
/* .shareDiv {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
} */
.flax {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
}

.lohp {
  width: 22px;
}

.cancel {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 20px;
  width: 318px;
  height: 51px;
  background: #f6f6f6;
  border-radius: 10px;
  cursor: pointer;
}

.cancel > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #111111;
}

.background {
  background: rgba(17, 17, 17, 0.6);
  z-index: 100;
}
