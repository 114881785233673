.form {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 20px;
  margin: 8px 0;
}
.form input,
textarea {
  border: 1px solid var(--secondary-color);
  width: 100%;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.form input:read-only,
.form textarea:read-only {
  border: none;
  border-bottom: 1px solid var(--secondary-color);
}
.form input[readonly]:focus,
textarea[readonly]:focus {
  border: none;
  border-bottom: 1px solid var(--secondary-color);
}
.form textarea {
  min-height: 100px;
  resize: none;
  overflow: auto;
}
.form input[type="color"] {
  width: 32px;
  height: 32px;
  padding: 2px;
  border-radius: 8px;
}
.form input[type="radio"] {
  width: 30px;
  height: 30px;
}
.form input[type="checkbox"] {
  width: 30px;
  height: 30px;
}
.form input:focus,
textarea:focus {
  border: 2px solid var(--secondary-color);
}
.form .formRadio {
  width: 20px;
}
.form .inputError {
  border: 1px solid rgb(255, 41, 41);
}
.error {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: end;
}
.changeImageButton {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 6px 16px;
  color: #000000;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  background: var(--supporting-color);
  border-radius: 30px;
}
