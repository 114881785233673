.box {
  background: #f7f9fb;
  border: 1px solid #ffc4c4;
  border-radius: 10px;
  width: 100%;
  padding: 12px;
  min-height: 151px;
}
.closing {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #1c1c1c;
  margin-bottom: 10px;
}
.closingText {
  max-width: 308px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #787878;
  margin-bottom: 10px;
}
.buttun {
  width: fit-content;
  padding: 6px 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff;
  background: #fd6b6b !important;
  border-radius: 50px;
  cursor: pointer;
}
