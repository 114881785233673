.container {
  height: 100%;
  width: 100%;
  padding: 20px;
}
.grid {
  height: 100%;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 20px;
}
.box {
  /* width: 390px;
  height: 426px; */
  height: 100%;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.entryQuestions {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 20px;
}

.imgWid {
  width: 24px;
  height: 24px;
  border-radius: 30px;
  object-fit: cover;
}

.inputland {
  width: 100%;
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  height: 67px;
  background: #f6f6f6;
  border-radius: 10px;
  margin-top: 20px;
}

.accept {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 20px;
  width: 100%;
  height: 51px;
  background: #111111;
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.decline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 20px;
  width: 100%;
  height: 51px;
  background: white;
  border-radius: 10px;
  border: 1px solid #111111;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #111111;
}

.flexme {
  gap: 16px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.bigImg {
  width: 100%;
  height: 174px;
  margin-top: 26px;
  object-fit: contain;
  object-position: top;
}
.video {
  width: 100%;
  height: 174px;
  margin-top: 26px;
  object-fit: contain;
  object-position: top;
}

.boxHead {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.background {
  position: absolute;
  top: 0px;
  height: 100vh;
  width: 100%;
  background: rgba(17, 17, 17, 0.6);
}
