.leadsHeading {
  padding: 20px;
}

.leadsDropDown {
  display: flex;
  position: relative;
}
.leadsDropDown h2 {
  font-weight: 500;
  font-size: 32px;
  line-height: 140%;
  color: #000000;
}
.leadsDropDown img {
  margin-left: 12px;
  padding: 8px 12px;
  border: 1px solid var(--supporting-color);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}
.dropDownPopup {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.1), 0px 9px 46px rgba(0, 0, 0, 0.06),
    0px 24px 38px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  min-width: 200px;
  z-index: 1;
}
.dropDownPopup h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #777777;
  padding: 15px;
  cursor: pointer;
}
.dropDownPopup p {
  padding: 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  border-top: 1px solid var(--supporting-color);
  border-bottom: 1px solid var(--supporting-color);
}

.leadsTable {
    width: 100%;
    text-align: left;
  }
  .leadsTable th,
  .leadsTable td {
    padding: 14px;
  }
  .leadsTable thead {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
    color: #787878;
  }
  .leadsTable tbody {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #585757;
  }
  .leadsTable th {
    white-space: nowrap;
    border: 1px solid var(--supporting-color);
  }
  .leadsTable tbody tr {
    border-bottom: 1px solid var(--supporting-color);
  }
  .slno {
    white-space: nowrap;
  }
  .name {
    white-space: nowrap;
  }
  .email {
    white-space: nowrap;
  }
  .email p {
    padding: 4px 16px;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #000000;
    background: var(--supporting-color);
    border-radius: 40px;
    text-align: center;
  }
  .phone {
    white-space: nowrap;
  }
  .date {
    white-space: nowrap;
  }
  .date p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }
  .requirment {
    white-space: normal;
    background: #f8f8f8;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #3361ff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  
  .requirment img {
    padding: 10px;
    cursor: pointer;
  }
  .addNew {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 24px 5px 6px;
    gap: 12px;
    width: 126px;
    height: 32px;
    background: var(--supporting-color);
    border-radius: 30px;
    cursor: pointer;
    white-space: nowrap;
  }