.editCampaign {
  width: 100%;
  height: 100vh;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.editCampaign h2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}
.editCampaign h3 {
  font-family: "Inter";
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}
.editCampaign p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}
.contentDiv {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: #ffffff;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: auto;
  border-bottom: 1px solid var(--supporting-color);
}

.headerLeft {
  display: flex;
  align-items: center;
  justify-content: center;
}

.body {
  display: grid;
  grid-template-columns: 1fr;
  height: 80%;
}
.body button {
  padding: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #ffffff;
  background: var(--primary-color);
}
.left {
  border-right: 1px solid var(--supporting-color);
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 20px;
}
.form {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 20px;
  margin: 8px 0;
}
.buttonWrapper {
  background: #ffffff;
  width: 100%;
  height: 94px;
  position: sticky;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top: 1px solid var(--supporting-color);
  bottom: 0;
  right: 0;
  padding: 32px 32px 18px 32px;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 12px;
  z-index: 1;
}
.buttonWrapper button {
  padding: 10px 4%;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
.buttonWrapper .discard {
  color: #344054;
  border: 1px solid var(--supporting-color);
}
.buttonWrapper .save {
  color: #ffffff;
  background: var(--primary-color);
}
.buttonWrapper:disabled {
  opacity: 50%;
}

.line {
  border-top: 1px solid var(--supporting-color);
  width: 100%;
}
