.parent {
  width: 100%;
  height: 100vh;
  padding: 94px 30px 30px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.parent h2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}
.parent h3 {
  font-family: "Inter";
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}
.parent p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: auto;
  border-bottom: 1px solid var(--supporting-color);
}

.headerLeft {
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerLeft img {
  padding: 10px;
  border: 1px solid var(--supporting-color);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 10px;
  margin-right: 16px;
}
.contentDiv {
  width: 50%;
  height: 100%;
  border-radius: 16px;
  background: #ffffff;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.body {
  padding: 0 10px 20px 10px;
}
