.card {
  padding: 15px 20px;
  width: 100%;
  /* height: 171px; */
  background: var(--supporting-color);
  border-radius: 10px;
  cursor: pointer;
  position: relative;
}
.upcomingCampaigns {
  background: #f0f0f0;
}
.pendingCampaigns {
  background: #f0f0f0;
  cursor: not-allowed;
}
.flexint {
  display: flex;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
}
.downShare {
  display: flex;
  margin-left: auto;
  gap: 16px;
}

.timer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 8px 6px 6px;
  gap: 10px;
  padding: 8px;

  width: max-content;
  height: 28px;
  /* background: rgba(255, 255, 255, 0.5); */
  backdrop-filter: blur(20px);
  border-radius: 30px;
}

.timer > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: black;
}

.text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #111111;
  text-align: center;
}

.campaingn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  text-align: left;

  margin-top: 20px;
}
.pendingCampaign {
  font-size: 12px;
  line-height: 29px;
  color: #000000;
  text-align: left;
}
.date {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  margin-bottom: 2px;
  text-align: left;
}
.abso {
  /* position: absolute; */
  bottom: 20px;
  gap: 12px;
  margin-top: 26px;
}
.flemOfpaty {
  display: flex;
  gap: 8px;
  align-items: center;
}
.participantText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}
.buttun {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 7px;
  width: max-content;
  height: 36px;
  background: var(--primary-color);
  border-radius: 50px;
  cursor: pointer;
}
.publishButton {
  padding: 9px 16px;
  background: #fd6b6b !important;
  border-radius: 60px;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #ffffff;
  white-space: nowrap;
}
.buttun > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #ffffff;
}
.numberOfparti {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px 6px;
  justify-content: center;
  min-width: 46px;
  width: max-content;
  height: 20px;
  background: rgba(126, 126, 126, 0.5);
  border-radius: 96px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: #000000;
}
