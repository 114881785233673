.reactPlayer {
  overflow: hidden;
  object-fit: contain;
}
.logo {
  height: 42px;
  min-width: 124px;
  object-fit: cover;
  margin-bottom: 10px;
}
.heading {
 
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
}
