.selectedWinners {
  margin-top: 30px;
}
.selectedWinners h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
}
.winnersGrid {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
}

.winnersGrid .entry {
  cursor: pointer;
  width: 100%;
  height: 216px;
  border-radius: 10px;
  background: var(--supporting-color);
}
.winnersGrid img {
  height: 130px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}
.winnersGrid p {
  font-weight: 600;
  font-size: 85%;
  line-height: 140%;
  color: #000000;
  padding: 10px;
}
