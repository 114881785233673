.loading {
  width: 100%;
  height: 5px;
  position: relative;
  animation: loading-animation 2s ease-in-out infinite;
}

.loading-bar {
  width: 100%;
  height: 5px;
  background: linear-gradient(
    89.99deg,
    #daff48 0%,
    #fcff4b 19.79%,
    #e788ff 82.75%,
    #ff8989 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: center;
}

@keyframes loading-animation {
  0% {
    transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
