.paginationParent {
  margin-top: 12px;
  display: flex;
  gap: 8px;
  width: 100%;
  justify-content: space-between;
  align-items: start;
}
.pages {
  display: flex;
  gap: 8px;
  width: 100%;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.paginationParent img {
  padding: 15px;
  border: 1px solid #d2dbff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  cursor: pointer;
}

.paginationParent p {
  padding: 8px 12px;
  border: 1px solid #d2dbff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #1c1c1c;
  cursor: pointer;
  white-space: nowrap;
}
