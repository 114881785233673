.carouselContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.carouselSlide {
  display: flex;
  gap: 10px;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}

.carouselSlide::-webkit-scrollbar {
  display: none;
}

.carouselSlide img {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  scroll-snap-align: center;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}

.carouselSlide img.active {
  opacity: 1;
}
.button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  border: none;
  background-color: transparent;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  opacity: 50%;
}
.button[disabled] {
  opacity: 0%;
  cursor: not-allowed;
}
.reportButton {
  border-radius: 12px;
  background: #262626;
  width: fit-content;
  min-width: 100px;
  box-shadow: 3px 3px 1px 0px rgba(0, 0, 0, 0.25);
  padding: 7px 12px;
  white-space: nowrap;
  transition: all 0.3 ease-in-out;
}
.reportButton:disabled {
  opacity: 50%;
  cursor: not-allowed;
}
