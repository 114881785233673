.reactPlayer {
  overflow: hidden;
  border-radius: 100%;
  object-fit: contain;
}
.heading {
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.tagline {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
}
