.box {
  width: 390px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 20px 20px 30px 20px;
}
.boxHead {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}
.bigImg {
  width: 100%;
  height: 174px;
  margin-top: 26px;
  object-fit: contain;
  object-position: top;
}
