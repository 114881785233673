.filterChips {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  margin-top: 33px;
  cursor: pointer;
}

.eachChips {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;

  width: max-content;
  height: 42px;
  background: var(--primary-color);
  border-radius: 40px;
}
.eachChips > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}
.eachChipsDark {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: max-content;
  height: 42px;
  background: var(--supporting-color);
  border-radius: 40px;
}
.eachChipsDark > p {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: max-content;
  height: 42px;
  color: #000000;
  border-radius: 40px;
}

.addNew {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 24px 5px 6px;
  gap: 12px;
  width: 126px;
  height: 32px;
  background: var(--supporting-color);
  border-radius: 30px;
  cursor: pointer;
}
.new {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #000000;
}

.promotionListGrid {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 12px;
}

.promotionListGrid .entry {
  cursor: pointer;
  width: 100%;
  min-height: 216px;
  border-radius: 10px;
  background: var(--supporting-color);
}
.promotionListGrid img {
  height: 130px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}
.promotionListGrid p {
  font-weight: 600;
  font-size: 85%;
  line-height: 140%;
  color: #000000;
  padding: 10px;
}
.editButton {
  padding: 5px 12px;
  background: #fd6b6b !important;
  border-radius: 60px;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #ffffff;
  white-space: nowrap;
  width: fit-content;
  align-self: flex-end;
}
