.loading {
  width: 100%;
  height: 100%;
  min-width: 20px;
  min-height: 20px;
  position: relative;
}

.loadingCircle {
  width: 100%;
  height: 100%;
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  border: 3px solid #f2f2f2;
  border-top-color: rgb(145, 77, 255);
  animation: loading-animation 1.2s linear infinite;
}

@keyframes loading-animation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
