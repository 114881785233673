.leadsTable {
  width: 100%;
  text-align: left;
}
.leadsTable th,
.leadsTable td {
  padding: 14px;
}
.leadsTable thead {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #787878;
}
.leadsTable tbody {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #585757;
  cursor: pointer;
}
.leadsTable th {
  white-space: nowrap;
  border: 1px solid var(--supporting-color);
}
.leadsTable td {
  border-right: 1px solid var(--supporting-color);
}

.leadsTable tbody tr {
  border-bottom: 1px solid var(--supporting-color);
}
.slno {
  white-space: nowrap;
}
.name {
  display: flex;
  gap: 8px;
}
.email {
  white-space: nowrap;
}
.reviewButton {
  padding: 10px 0;
  background: #097b15;
  border-radius: 8px;
  text-align: center;
  color: #ffffff;
  width: 138px;
}
.paymentDisabled {
  opacity: 50%;
}
.reviewButton:disabled {
  opacity: 50%;
}
