.box {
  margin-top: 30px;
  padding: 16px;
  position: relative;
}

.declarme {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
}

.declbutn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 20px;
  width: 100%;
  height: 51px;
  background: #111111;
  border-radius: 10px;
  cursor: pointer;
}

.declbutn > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.StatusClosed {
  opacity: 0.2;
  cursor: not-allowed;
}
.inlines {
  display: inline;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #111111;
}

.dowlodePdf {
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 8px;
  width: 100%;
  height: 56px;
  background: #f6f6f6;
  border-radius: 10px;
}
.contestClosed {
  opacity: 0.2;
}
.dowlodePdf > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #111111;
}
